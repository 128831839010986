import React, { useEffect, useState } from "react";

import { useWindowScroll } from "react-use";
import { CourseEvent, CourseEventBookingUrl } from "../../models/CourseEvent";
import moment from "moment";
import {
    EventRegisterSection,
    MobileTimeContainer,
    RegisterButton,
    RegisterButtonText,
    RegisterContainer,
    RegisterTextContainer,
    TimeContainer,
} from "./styles/EventRegister";
import classNames from "classnames";
import Button from "components/shared/Button";

interface EventRegisterProps {
    courseEvent: CourseEvent;
}

const EventRegister = (props: EventRegisterProps) => {
    const { courseEvent } = props;
    const { y: pageYOffset } = useWindowScroll();
    const [scrolled, setScrolled] = useState<boolean>(false);

    useEffect(() => {
        if (pageYOffset > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }, [pageYOffset]);

    const getDateAndTime = (shouldReturnTime: boolean) => {
        const startTime = moment.tz(courseEvent.startDate, courseEvent.locationTimezone ?? moment.tz.guess());
        const endTime = moment.tz(courseEvent.endDate, courseEvent.locationTimezone ?? moment.tz.guess());

        const day = moment.tz(courseEvent.startDate, courseEvent.locationTimezone ?? moment.tz.guess());

        if (!day.isValid()) {
            return "Date TBD";
        }

        let time = `${startTime.format("h:mm a")}-${endTime.format("h:mm a")}`;
        if (!startTime.isValid() || startTime.hours() === 0) {
            time = "";
        } else if (!endTime.isValid()) {
            time = `${startTime.format("h:mm a")}`;
        }

        return shouldReturnTime && time ? `${day.format("MMMM DD YYYY")}, ${time}` : day.format("MMMM DD YYYY");
    };

    return (
        <div className={classNames("position-fixed bottom-0 w-full bg-primary transition-transform z-10", {
            "translate-y-full": !scrolled,
            "translate-y-0": scrolled
        })}>
            <div className="container">
                <EventRegisterSection>
                    {courseEvent.startDate
                        ? <>
                            <RegisterTextContainer>
                                <MobileTimeContainer>
                                    {courseEvent.title}:<br /> {getDateAndTime(false)}
                                </MobileTimeContainer>
                                <TimeContainer>
                                    {courseEvent.title}: {getDateAndTime(true)}
                                </TimeContainer>
                            </RegisterTextContainer>
                            {courseEvent.bookingUrl &&
                                <Button
                                    variant="secondary"
                                    {...(courseEvent.allowAnonymous ? { href: courseEvent.bookingUrl } : { to: CourseEventBookingUrl(courseEvent) })}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Register now
                                </Button>}
                        </>
                        : <RegisterTextContainer>
                            <div className="py-2.5">
                                <strong>{courseEvent.title}</strong>: This has been postponed.
                            </div>
                        </RegisterTextContainer>}
                </EventRegisterSection>
            </div>
        </div>
    );
};

export default EventRegister;
