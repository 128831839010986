import { Course } from "./Course";
import { CourseEventFaq } from "./CourseEventFaq";
import { CourseEventPricingItem } from "./CourseEventPricingItem";
import { CourseEventSchedule } from "./CourseEventSchedule";
import { CourseEventSpeaker } from "./CourseEventSpeaker";

export interface CourseEvent {
    id: number;
    courseId: number;
    title: string;
    description: string;
    summary: string;
    capacity: number;
    availability: number;
    startDate: Date;
    endDate: Date;
    locationVenue: string;
    bookingUrl: string;
    locationStreatAddress: string;
    locationCity: string;
    locationLat: string;
    locationLong: string;
    locationImage: string;
    locationTimezone?: string;
    racgpTitleMd: string;
    racpgDescription: string;
    cpdHoursInfo?: string;
    cpdHoursFootnote?: string;
    highlightOneTitle: string;
    highlightOneDescription: string;
    highlightTwoTitle: string;
    highlightTwoDescription: string;
    highlightThreeTitle: string;
    highlightThreeDescription: string;
    speakersDescription: string;
    registerButtonText: string;
    sponsorLogoBase64?: string;
    course: Course;
    courseEventSchedules: CourseEventSchedule[];
    courseEventPricingItems: CourseEventPricingItem[];
    courseEventSpeakers: CourseEventSpeaker[];
    courseEventFaqs: CourseEventFaq[];
    allowAnonymous: boolean;
}

export function CourseEventBookingUrl(e: CourseEvent) {
    if (e.allowAnonymous) {
        return e.bookingUrl;
    }
    return `/face-to-face-events/${e.id}/book`;
}
