import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import * as dashboard from '../../store/dashboardStore'
import { connect } from 'react-redux'
import { IApplicationState } from '../../store'
import { ReactComponent as LinePattern } from "../../images/line-pattern.svg";
import { Card } from 'components/reactstrap'
import Button from 'components/shared/Button'
import { EventLinkTreeService } from 'services/EventLinkTreeService'
import { Spinner } from 'reactstrap'
import { EventLinkTree } from 'models/EventLinkTree'
import { CourseEvent } from 'models/CourseEvent'
import { CourseService } from 'services/CourseService'
import moment from 'moment'

interface OwnRouteProps {
    id: string
}

const LocationCard = ({ title, courseEvent }: { title: string, courseEvent: CourseEvent }) => {

    const src = courseEvent.locationImage ? `data:image/png;base64,${courseEvent.locationImage}` : courseEvent.course.thumbnailUrl;

    const start = moment.tz(courseEvent.startDate, courseEvent.locationTimezone ?? moment.tz.guess());
    const end = moment.tz(courseEvent.endDate, courseEvent.locationTimezone ?? moment.tz.guess());

    return <Card.Shell className='w-96 max-w-full flex flex-col' as="a" href={`/event/${courseEvent.id}`}>
        {src && <img src={src} className='object-cover object-center w-full h-64' alt={courseEvent.course.title} />}
        <Card.Body className='flex-1 flex flex-col'>
            <div className='flex-1'>
                <h3>{title}</h3>
                {start.isValid() && end.isValid()
                    ? start.diff(end, 'days') === 0 ?
                        <>
                            <p><strong className='font-semibold'>{start.format("dddd, MMMM Do, YYYY")}</strong></p>
                            <p className='font-medium'>{start.format("LT")} - {end.format("LT")}</p>
                        </>
                        : <>
                            <p className='font-medium'>
                                <strong className='font-semibold'>{start.format("LLLL")}</strong>
                                {' '}until{' '}
                                <strong className='font-semibold'>{end.format("LLLL")}</strong>
                            </p>
                        </>
                    : <p className='font-medium'>Date TBD</p>}
                {courseEvent.locationVenue && <p className='font-normal'>{courseEvent.locationVenue}</p>}
            </div>
            <Button className='mt-8 w-full'>View event</Button>
        </Card.Body>

    </Card.Shell>
}

const Sponsor = ({ title, href, src }) => {
    const Tag = href ? "a" : "span"
    return <Tag href={href} title={title} className='flex items-center justify-content-center w-fit sm:ml-auto mr-auto'><img src={src} alt={title} /></Tag>
}

type Props = RouteComponentProps<OwnRouteProps> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators
const UpcomingEventGroup: React.FC<Props> = (props: Props) => {
    const courseEventGroupId = parseInt(props.match.params.id);

    const [linkTree, setLinkTree] = useState<EventLinkTree>();
    const [courseEvents, setCourseEvents] = useState<CourseEvent[]>([]);

    useEffect(() => {
        (async () => {
            const xd = await EventLinkTreeService.get(courseEventGroupId);
            if (!xd.hasErrors) {
                setLinkTree(xd.value);

                const courseEvents = await Promise.all(xd.value.branches.map((async branch => {
                    const courseEvent = await CourseService.GetEventById(branch.courseEventId);
                    if (!courseEvent.hasErrors) {
                        return courseEvent.value
                    }
                })));

                setCourseEvents(courseEvents);
            }
        })();
    }, [courseEventGroupId]);

    if (!linkTree) {
        return <Spinner />
    }

    return (
        <React.Fragment>
            <div className="flex flex-col">
                {/* TOP SECTION */}
                <div className='relative overflow-hidden from-transparent to-primary-25 bg-gradient-to-r'>
                    <LinePattern className="absolute top-0 right-0" />
                    <div className="container py-12 sm:py-32 relative z-1">
                        <div className='lg:px-56 space-y-6'>
                            <h1 className='text-primary text-center'>{linkTree.title}</h1>
                            {linkTree.description &&
                                <p className='text-center'>{linkTree.description}</p>
                            }
                        </div>
                    </div>
                </div>

                {/* EVENT LOCATIONS */}
                <div className='bg-primary-50 py-12 sm:py-24' id="events">
                    <div className='container'>
                        <h2 className='text-center mb-8 sm:mb-16'>Event locations</h2>
                        <div className='flex flex-row gap-8 justify-content-center flex-wrap'>
                            {linkTree.branches
                                .sort((a, b) => (a.order - b.order))
                                .map((x, i) => {
                                    const courseEvent = courseEvents.find(ev => ev.id === x.courseEventId);
                                    if (!courseEvent) {
                                        return <React.Fragment key={x.courseEventId} />
                                    }
                                    return <LocationCard
                                        title={x.label ?? courseEvent?.course.title}
                                        key={x.courseEventId}
                                        courseEvent={courseEvent}
                                    />
                                })}

                        </div>
                    </div>
                </div>

                {/* EVENT LOCATIONS */}
                {!!linkTree.sponsors.length &&
                    <div className='bg-primary-25 py-12 sm:py-24' id="sponsors">
                        <div className='container'>
                            <h2 className='mb-4 sm:mb-5'>Sponsors</h2>
                            {linkTree.sponsorDescription && <p>{linkTree.sponsorDescription}</p>}
                            <div className='mt-4 sm:mt-10 gap-8 sm:gap-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'>
                                {linkTree.sponsors.map((x, i) => <Sponsor
                                    title={x.name}
                                    src={`data:image/png;base64,${x.imageBase64}`}
                                    href={x.url}
                                    key={i}
                                />)}

                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators },
)(UpcomingEventGroup as any)
