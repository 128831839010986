import React, { useEffect, useState } from 'react'
import AcademyLogoImage from '../../images/academy-logo.svg'
import { Link, useLocation } from 'react-router-dom'
import {
  BackgroundBlurOverlay,
  HeaderIcon,
  LandingPageMobileMenu,
  MobileButtonsContainer,
  MobileNavMenu,
  MobileNavMenuContainer,
  NavbarContainer,
} from './styles/Header'
import './styles/LandingPageHeader.scss'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import iconDropdown from '../../images/events-dropdown.svg'
import { CourseEvent } from '../../models/CourseEvent'
import { StyledSVG } from '../dashboard/styles/Common'
import moment from 'moment'
import styled, { css } from 'styled-components'
import { useWindowScroll } from 'react-use'
import TopBanner from '../landing-page/TopBanner'
import { device } from '../../styles/sizes/device'
import { PaddingContainer } from '../landing-page/styles/General'
import Button from './Button'

interface IProps {
  upcomingEvents: CourseEvent[]
  topUpcomingEvent: CourseEvent | null
}

type Props = IProps
export const LandingPageHeader: React.FC<Props> = (props: Props) => {
  const { upcomingEvents, topUpcomingEvent } = props

  const [isDropDownOpen, toggleDropDown] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [
    showMobileMenuUpcomingEvents,
    setShowMobileMenuUpcomingEvents,
  ] = useState(false)

  const { y: pageYOffset } = useWindowScroll()
  const [scrolled, setScrolled] = useState<boolean>(false)

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [showMobileMenu])

  useEffect(() => {
    if (pageYOffset > 30) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }, [pageYOffset])

  const renderMenu = () => {
    return (
      <>
        <NavbarContainer
          scrolled={scrolled}
          topBannerIsVisible={topUpcomingEvent != null}
        >
          <div className="container">
            <PaddingContainer>
              <div className="flex justify-between desktop-menu">
                <div className="flex align-items-center menu-items">
                  <Link to="/home">
                    <HeaderIcon
                      backgroundImage={AcademyLogoImage}
                      width={114}
                      height={44}
                      mobileWidth={114}
                      mobileHeight={44}
                      marginLeft={0}
                      marginRight={0}
                    />
                  </Link>
                  <Link
                    to="/home"
                    className="ms-sm-5 me-md-4"
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  >
                    Home
                  </Link>
                  <span className="me-md-4">
                    {upcomingEvents && (
                      <Dropdown
                        isOpen={isDropDownOpen}
                        toggle={() => toggleDropDown(!isDropDownOpen)}
                        onMouseEnter={() => toggleDropDown(true)}
                        onMouseLeave={() => toggleDropDown(false)}
                        direction="down"
                      >
                        <DropdownToggle
                          className="toggle-span"
                          tag="span"
                          role="button"
                        >
                          Upcoming events
                        </DropdownToggle>
                        <DropdownMenu className="event-dropdown-center">
                          {upcomingEvents.map((e) => {
                            let startDate = moment.tz(e.startDate, e.locationTimezone ?? moment.tz.guess())
                            return (
                              <DropdownItem
                                style={{
                                  width: '336px',
                                  padding: 0,
                                }}
                                key={e.id}
                              >
                                <Link
                                  style={{
                                    display: 'flex',
                                    width: '336px',
                                  }}
                                  to={'/event/' + e.id}
                                >
                                  <div className="event-dropdown-item">
                                    <div className="event-dropdown-item-title">
                                      {e.title}
                                    </div>
                                    <div className="event-dropdown-item-date">
                                      {startDate.date()}{' '}
                                      {startDate.format('MMMM')}{' '}
                                      {startDate.year()}
                                    </div>
                                  </div>
                                </Link>
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </span>
                  <Link
                    to="/"
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  >
                    Learning portal
                  </Link>
                </div>
                <div>
                  <Link to="/auth/signin" className="me-md-4 menu-items">
                    Log In
                  </Link>
                  <Link to="/auth/signup" className="btn header-login-button">
                    Create account
                  </Link>
                </div>
              </div>
            </PaddingContainer>
          </div>
        </NavbarContainer>
      </>
    )
  }

  const renderMobileMenu = () => {
    return (
      <>
        {showMobileMenu && <BackgroundBlurOverlay />}

        <MobileNavMenu
          topBannerIsVisible={topUpcomingEvent != null}
          scrolled={scrolled}
          showMobileMenu={showMobileMenu}
        >
          <MobileNavMenuContainer>
            <div>
              <Link to="/home">
                <HeaderIcon
                  backgroundImage={AcademyLogoImage}
                  width={114}
                  height={44}
                  mobileWidth={114}
                  mobileHeight={44}
                  marginLeft={0}
                  marginRight={0}
                />
              </Link>
            </div>
            <div
              role="button"
              className="ms-auto"
              onClick={() => setShowMobileMenu((p) => !p)}
            >
              {showMobileMenu ? (
                <img
                  src={
                    require('../../images/icon-close-hamburger-menu.svg')
                      .default
                  }
                />
              ) : (
                <img
                  src={require('../../images/icon-hamburger-menu.svg').default}
                />
              )}
            </div>
          </MobileNavMenuContainer>
          {showMobileMenu && (
            <LandingPageMobileMenu
              className="position-absolute w-full"
              scrolled={scrolled}
              topBannerIsVisible={topUpcomingEvent != null}
            >
              <div className="h-100 main-card">
                <div
                  onClick={() => {
                    setShowMobileMenu((p) => !p)
                  }}
                >
                  <Link
                    to="/home"
                    className="landing-page-mobile-menu-item"
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  >
                    Home
                  </Link>
                </div>
                <div>
                  <div
                    className="flex justify-between align-items-center"
                    onClick={() => {
                      setShowMobileMenuUpcomingEvents(
                        !showMobileMenuUpcomingEvents,
                      )
                    }}
                  >
                    <div className="landing-page-mobile-menu-item">
                      Upcoming events
                    </div>
                    <div>
                      <StyledSVG
                        src={iconDropdown}
                        marginLeft="0"
                        marginRight="20px"
                        transform={
                          showMobileMenuUpcomingEvents
                            ? 'rotate(180)'
                            : 'rotate(0)'
                        }
                      />
                    </div>
                  </div>
                  {showMobileMenuUpcomingEvents &&
                    upcomingEvents.map((e) => {
                      let startDate = moment.tz(e.startDate, e.locationTimezone ?? moment.tz.guess())
                      return (
                        <Link
                          key={e.id}
                          onClick={() => {
                            setShowMobileMenu((p) => !p)
                          }}
                          to={'/event/' + e.id}
                        >
                          <div
                            className="event-dropdown-item"
                            onClick={() =>
                              setShowMobileMenuUpcomingEvents(
                                !showMobileMenuUpcomingEvents,
                              )
                            }
                          >
                            <div className="event-dropdown-item-title">
                              {e.title}
                            </div>
                            <div className="event-dropdown-item-date">
                              {startDate.date()} {startDate.format('MMMM')}{' '}
                              {startDate.year()}
                            </div>
                          </div>
                        </Link>
                      )
                    })}
                </div>
                <div
                  onClick={() => {
                    setShowMobileMenu((p) => !p)
                  }}
                >
                  <Link
                    className="landing-page-mobile-menu-item"
                    to="/"
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  >
                    Learning portal
                  </Link>
                </div>
                <div className="flex flex-col landing-page-mobile-menu-buttons">
                  <hr />
                  <MobileButtonsContainer>
                    <Button to="/auth/signup">Create account</Button>
                    <Button to="/auth/signin" variant="secondary">
                      Login
                    </Button>
                  </MobileButtonsContainer>
                </div>
              </div>
            </LandingPageMobileMenu>
          )}
        </MobileNavMenu>
      </>
    )
  }

  return (
    <>
      {topUpcomingEvent && <TopBanner event={topUpcomingEvent} />}
      {renderMenu()}

      {renderMobileMenu()}
    </>
  )
}
