import React from 'react'

import { CourseEvent } from '../../models/CourseEvent'
import { SectionTitleContainer } from './styles/EventHighlights'
import {
  EventScheduleCard,
  EventScheduleStepConnector,
  EventScheduleStepConnectorContainer,
  EventScheduleStepContainer,
  EventScheduleStepIconContainer,
  EventScheduleStepIconNumberContainer,
  EventScheduleStepIconNumberTextContainer,
  EventScheduleStepTextContainer,
  EventScheduleStepTextDescription,
  EventScheduleStepTextTitle,
  EventScheduleTextItem,
  ScheduleCardHeader,
} from './styles/EventDetails'
import { Card } from 'components/reactstrap'

interface EventScheduleProps {
  courseEvent: CourseEvent
}

const EventSchedule = (props: EventScheduleProps) => {
  const { courseEvent } = props

  const renderIcon = (index: number) => {
    return (
      <div className='w-10 h-10 flex items-center justify-center rounded-full bg-primary-50 text-primary border-primary border-2 text-xl font-semibold'>
        {index}
      </div>
    )
  }

  const TextWithBreaks = ({ text }) => {
    const lines = text.split('\n')
    return (
      <span>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            <EventScheduleTextItem>{line}</EventScheduleTextItem>
          </React.Fragment>
        ))}
      </span>
    )
  }

  const renderItem = (
    index: number,
    title: string,
    description: string | null,
    shouldRenderConnector: boolean = true,
  ) => {
    return (
      <EventScheduleStepContainer key={`event-schedule-item-${index}`}>
        <div className='flex items-center self-stretch flex-col'>
          <div className='w-10 h-10 flex items-center justify-center rounded-full bg-primary-50 text-primary border-primary border-2 text-xl font-semibold'>
            {index}
          </div>
          {shouldRenderConnector && <div className='w-0 border-l-2 border-primary flex-1 my-1'></div>}
        </div>
        <EventScheduleStepTextContainer>
          <EventScheduleStepTextTitle>{title}</EventScheduleStepTextTitle>
          {description && (
            <EventScheduleStepTextDescription>
              <TextWithBreaks text={description} />
            </EventScheduleStepTextDescription>
          )}
        </EventScheduleStepTextContainer>
      </EventScheduleStepContainer>
    )
  }

  if (!courseEvent.courseEventSchedules?.length) {
    return null;
  }

  return (
    <Card className='max-w-md'>
      <h2 className='mb-4'>Schedule*</h2>
      {courseEvent.courseEventSchedules.map((schedule, index) => {
        return renderItem(
          schedule.order,
          schedule.title,
          schedule.description,
          index + 1 !== courseEvent.courseEventSchedules.length,
        )
      })}
      <p>
        * Specific timing of schedule subject to change
      </p>
    </Card>
  )
}

export default EventSchedule
