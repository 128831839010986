import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const RegisterSection = styled.section`
    background: var(--gray-50, #f9fafb);
    padding-top: 150px;

    @media ${device.xs} {
        padding: 0px;
    }
`;

export const RegisterSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    @media ${device.xs} {
        padding: 48px 16px;
    }
`;

export const RegisterSectionTitleContainer = styled.div`
    display: flex;
    padding: 0px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    @media ${device.xs} {
        padding: 0px;
    }
`;

export const RegisterSectionTitleContainerContent = styled.div`
    display: flex;
    padding: 0px 32px;
    justify-content: center;
    gap: 10px;
    align-self: stretch;

    @media ${device.xs} {
        padding: 0px;
    }
`;

export const RegisterSectionTitleText = styled.h2`
    color: var(--gray-900, #101828);
    text-align: center;
    /* Display sm/Semibold */

    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */

    @media ${device.xs} {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const RegisterSectionTitleTextHighlighted = styled.span`
    color: var(--primary-600, #f47721);
    /* Display sm/Semibold */

    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;

    @media ${device.xs} {
        font-size: 24px;
        line-height: 32px;
        display: block;
    }
`;
