import React from "react";
import {
    EventDateContainer,
    EventHeaderSection,
    EventInfoContainer,
    EventParagraphContainer,
    EventHeaderSectionContainer,
    EventTitleContainer,
    EventTopImageContainer,
    EventTopImageOverflow,
    Paragraph,
    RegisterButtonContainer,
} from "./styles/EventHeader";
import { CourseEvent, CourseEventBookingUrl } from "../../models/CourseEvent";
import { ReactComponent as LinePattern } from "../../images/line-pattern.svg";
import moment from "moment";
import Button from "components/shared/Button";
import { Col, Row } from "reactstrap";

interface EventHeaderProps {
    courseEvent: CourseEvent;
}

const EventHeader = (props: EventHeaderProps) => {
    const { courseEvent } = props;

    const getDate = () => {
        const day = moment.tz(courseEvent.startDate, courseEvent.locationTimezone ?? moment.tz.guess());

        if (!day.isValid()) {
            return "Date TBD"
        }
        return day.format("MMMM DD YYYY");
    };

    const getTime = () => {
        const startTime = moment.tz(courseEvent.startDate, courseEvent.locationTimezone ?? moment.tz.guess());
        const endTime = moment.tz(courseEvent.endDate, courseEvent.locationTimezone ?? moment.tz.guess());

        if (!startTime.isValid() || startTime.hours() === 0) {
            return null
        }
        if (!endTime.isValid()) {
            return `${startTime.format("h:mm a")}`
        }
        return `${startTime.format("h:mm a")}-${endTime.format("h:mm a")}`
    };

    const TextWithBreaks = ({ text }) => {
        const lines = (text ?? "").split("\n");
        return (
            <div>
                {lines.map((line, index) => (
                    <Paragraph key={index}>{line}</Paragraph>
                ))}
            </div>
        );
    };

    return (
        <EventHeaderSection>
            <div className="container">
                <EventTopImageOverflow>
                    <EventTopImageContainer>
                        <LinePattern />
                    </EventTopImageContainer>
                </EventTopImageOverflow>
                <EventHeaderSectionContainer>
                    <Row>
                        <Col md={8}>
                            <div className="flex flex-col gap-4 items-start">
                                <EventDateContainer>
                                    <div>{getDate()}</div>
                                    <div>{getTime()}</div>
                                </EventDateContainer>
                                <h1 className="text-primary leading-tight">
                                    {courseEvent.title}
                                </h1>
                                {courseEvent.description && <h2>
                                    {courseEvent.description}
                                </h2>}
                                {courseEvent.summary && <EventParagraphContainer>
                                    <TextWithBreaks text={courseEvent.summary} />
                                </EventParagraphContainer>}
                            </div>
                        </Col>
                        {courseEvent.course?.thumbnailUrl &&
                            <Col className="hidden md:block" md={4}>
                                <div className="flex flex-col h-full items-end justify-center">
                                    <img alt="" className="max-w-full" src={courseEvent.course?.thumbnailUrl} />
                                </div>
                            </Col>
                        }
                    </Row>
                    <RegisterButtonContainer>
                        {courseEvent.startDate
                            ? courseEvent.bookingUrl
                                ? <Button
                                    {...(courseEvent.allowAnonymous ? { href: courseEvent.bookingUrl } : { to: CourseEventBookingUrl(courseEvent) })}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        (window as any).gtag("event", "conversion", {
                                            send_to: `${process.env.REACT_APP_TAG_MANAGER_ID}/${process.env.REACT_APP_EVENT_LEAD_CONVERSION_ID}`,
                                        });
                                    }}
                                >
                                    Register for this event
                                </Button>
                                : undefined
                            : <strong className="text-lg">This has been postponed.</strong>
                        }
                    </RegisterButtonContainer>
                </EventHeaderSectionContainer>
            </div>
        </EventHeaderSection>
    );
};

export default EventHeader;
