import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const EventDetailsSection = styled.section`
    padding: 96px 0px;
    background: var(--orange-25);
    @media ${device.xs} {
        padding: 48px 0px;
    }
`;

export const EventDetailsSectionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    @media ${device.sm} {
        flex-direction: column;
        gap: 48px;
    }
`;

export const ScheduleCardHeader = styled.div`
    display: flex;
    padding-bottom: 32px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    @media ${device.xs} {
        padding-bottom: 24px;
    }
`;

export const EventScheduleCard = styled.div`
    display: flex;
    width: 500px;
    padding: 32px 32px 0px 32px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid var(--gray-200);
    background: var(--white);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    @media ${device.sm} {
        width: 100%;
        padding: 24px 24px 0px 24px;
    }
`;

export const EventScheduleStepContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    @media ${device.xs} {
        gap: 12px;
    }
`;

export const EventScheduleStepConnectorContainer = styled.div`
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
`;

export const EventScheduleStepConnector = styled.div`
    width: 2px;
    flex: 1 0 0;
    border-radius: 2px;
    background: var(--primary-600);
    margin-bottom: 4px;
`;

export const EventScheduleStepIconContainer = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--primary-50);
    @media ${device.xs} {
        width: 24px;
        height: 24px;
    }
`;

export const EventScheduleStepIconNumberContainer = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    padding-top: 3px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    border: 2px solid var(--primary-600);
    @media ${device.xs} {
        border: 1.5px solid var(--primary-600);
        border-radius: 12px;
        width: 24px;
        height: 24px;
    }
`;

export const EventScheduleStepIconNumberTextContainer = styled.span`
    display: flex;
    width: 34px;
    flex-direction: column;
    flex-shrink: 0;
    align-self: stretch;
    color: var(--primary-600);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    @media ${device.xs} {
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
    }
`;

export const EventScheduleStepTextContainer = styled.ul`
    display: flex;
    padding: 6px 0px 32px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;

    @media ${device.xs} {
        padding: 2px 0px 24px 0px;
        gap: 0px;
    }
`;

export const EventScheduleStepTextTitle = styled.li`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    @media ${device.xs} {
        font-size: 14px;
        line-height: 20px;
    }
`;

export const EventScheduleStepTextDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    @media ${device.xs} {
        font-size: 14px;
        line-height: 28px;
    }
`;

export const EventSpeakersAndSponsorContainer = styled.div`
    display: flex;
    padding: 32px 32px 0px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 96px;
    flex: 1 0 0;
    @media ${device.xs} {
        gap: 32px;
        padding: 0px 8px;
    }
`;

export const EventSpeakerContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
`;

export const EventSpeakerHeadingAndDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    @media ${device.xs} {
        gap: 16px;
    }
`;

export const EventSpeakerDescriptionContainer = styled.p`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const EventSponsorContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
`;

export const EventSponsorHeadingAndDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    @media ${device.xs} {
        gap: 16px;
    }
`;

export const EventSponsorDescriptionContainer = styled.p`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const EventSponsorImageContainer = styled.img`
    width: 300px;
    @media ${device.xs} {
        width: 200px;
    }
`;

export const EventSpeakersContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
`;

export const EventSpeakerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media ${device.xs} {
        flex-direction: row;
    }
`;

export const EventSpeakerImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const EventSpeakerImage = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 200px;
`;

export const EventSpeakerTextAndSocialLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`;

export const EventSpeakerNameAndSupportingTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

export const EventSpeakerNameAndRoleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const EventSpeakerName = styled.h3`
    color: var(--gray-900, #101828);
    /* Text lg/Semibold */

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 0px;
`;
export const EventSpeakerRole = styled.p`
    color: var(--gray-800, #1d2939);
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

export const EventSpeakerTopic = styled.p`
    color: var(--gray-800, #1d2939);
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

export const EventSpeakerShortDescription = styled.p`
    color: var(--gray-600, #475467);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

export const EventSpeakerSocialMediaContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const EventSpeakerSocialMediaIcon = styled.img``;

export const EventScheduleTextItem = styled.p`
    color: var(--gray-600, #475467);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 8px;
`;
